import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownItem,
} from "mdbreact";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Box } from "@material-ui/core";
import { DropdownMenu, DropdownToggle } from "./Dropdown";
const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleCollapse = () => setOpen(!isOpen);

  const history = useHistory();

  const routeService = () => history.push("/service");

  return (
    <React.Fragment>
      <MDBNavbar color="white" expand="xl" className="MainHeader">
        <MDBContainer>
          <MDBNavbarBrand>
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/MainLogo.png`}
                height="30"
                alt=""
              />
            </Link>
          </MDBNavbarBrand>

          <Box
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "block",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <MenuIcon onClick={toggleCollapse} />
          </Box>

          <MDBCollapse id="Navigation" isOpen={isOpen} navbar>
            <MDBNavbarNav right className="linksbar">
              <MDBNavItem>
                <MDBNavLink to="/">Home</MDBNavLink>
              </MDBNavItem>

              <MDBNavItem>
                <MDBDropdown>
                  <DropdownToggle id="Drops" onClick={routeService}>
                    <span className="mr-2">Solutions</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <MDBDropdownItem href="/service#sysDesign">
                      System Design
                    </MDBDropdownItem>
                    <MDBDropdownItem href="/service#sysIntegration">
                      System Integration
                    </MDBDropdownItem>
                    <MDBDropdownItem href="/service#softwareDevelopment">
                      Software Development
                    </MDBDropdownItem>
                    <MDBDropdownItem href="/service#deepLearning">
                      Analytics and Deep Learning
                    </MDBDropdownItem>
                  </DropdownMenu>
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem>
                <MDBNavLink to="/bite">BITE</MDBNavLink>
              </MDBNavItem>

              {/* <MDBNavItem>
                <MDBNavLink to="/team">Team</MDBNavLink>
              </MDBNavItem> */}

              <MDBNavItem>
                <MDBNavLink to="/client">Clients</MDBNavLink>
              </MDBNavItem>

              {/* <MDBNavItem>
                <MDBNavLink to="/blog">Blog</MDBNavLink>
              </MDBNavItem> */}

              <MDBNavItem>
                <MDBNavLink to="/contact">Contact</MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </React.Fragment>
  );
};

export default Header;
