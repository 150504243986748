import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import Service from "./Service";
// import Team from './Team'
// import Team from "./TeamMember/Team";
import UseCases from "./UseCases/UseCases";
import Client from "./Client";
import Bite from "./Bite";
import Blog from "./Blog";
import PDCPressReleaseBlog from "./BlogDetails/PDCPressRelease";
import Q12022NewsletterBlog from "./BlogDetails/Newsletters/Q12022Newsletter";
import Q32021NewsletterBlog from "./BlogDetails/Newsletters/Q32021Newsletter";
import Q42021NewsletterBlog from "./BlogDetails/Newsletters/Q42021Newsletter";
import ZambesiaPartnershipBlog from "./BlogDetails/ZambesiaPartnership";
import SteelFlangePost from "./BlogDetails/SteelFlangePost";
import Stars3 from "./Stars3";
import SanctionsEvasionBlog from "./BlogDetails/SanctionsEvasion";
import MyanmarTeakBlog from "./BlogDetails/MyanmarTeakBlog";
import HIKChinaBlog from "./BlogDetails/HIKChinaBlog";

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/contact" component={Contact} />
      <Route path="/service" component={Service} />
      {/* <Route path="/team" component={Team} /> */}
      <Route path="/use-cases" component={UseCases} />
      <Route path="/client" component={Client} />
      <Route path="/blog" component={Blog} />
      <Route path="/bite" component={Bite} />
      <Route path="/pdc-press-release" component={PDCPressReleaseBlog} />
      <Route path="/2022-q1-newsletter" component={Q12022NewsletterBlog} />
      <Route path="/2021-q3-newsletter" component={Q32021NewsletterBlog} />
      <Route path="/2021-q4-newsletter" component={Q42021NewsletterBlog} />
      <Route path="/zambesia-partnership" component={ZambesiaPartnershipBlog} />
      <Route path="/sanctions-evasion" component={SanctionsEvasionBlog} />
      <Route path="/steel-flange-case-study" component={SteelFlangePost} />
      <Route path="/myanmar-teak-blog" component={MyanmarTeakBlog} />
      <Route path="/hik-china-blog" component={HIKChinaBlog} />
      <Route path="/stars3" component={Stars3} />
    </Switch>
  </main>
);

export default Main;
